const BUSD_ABI = require("../Contracts/BUSD_ABI.json");
const ContractABI = require("../Contracts/ContractABI.json");

const arr = {
  BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
  BUSD_ABI: BUSD_ABI,
  Contract:"0xb75FEfD34B2590E7ee2D85B2B440D2364BC4Ea58",
  ContractABI:ContractABI
};

const ContractDetails = arr;
module.exports = ContractDetails;
